import request from '@/utils/request'

const userSupplierApi = {
  user_supplier_list: '/user_supplier/',
  user_supplier_create: '/user_supplier/',
  user_supplier_update: '/user_supplier/',
  user_supplier_delete: '/user_supplier/'
}

/**
 * 列表
 */
export function user_supplier_list (parameter) {
  return request({
    url: userSupplierApi.user_supplier_list,
    method: 'get',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    params: parameter
  })
}
/**
 * 添加
 */
export function user_supplier_create (parameter) {
  return request({
    url: userSupplierApi.user_supplier_create,
    method: 'post',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    data: parameter
  })
}
/**
 * 修改
 */
export function user_supplier_update (parameter, user_supplier_id) {
  return request({
    url: userSupplierApi.user_supplier_update + user_supplier_id + '/',
    method: 'put',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    data: parameter
  })
}
/**
 * 删除
 */
export function user_supplier_delete (user_supplier_id) {
  return request({
    url: userSupplierApi.user_supplier_delete + user_supplier_id + '/',
    method: 'delete',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    }
  })
}

<template>
  <a-modal
    title="创建用户供应商关联"
    :width="1080"
    :visible="visible"
    :confirmLoading="loading"
    :footer="null"
    @ok="() => { $emit('ok') }"
    @cancel="() => { $emit('cancel') }"
  >
    <a-spin :spinning="loading">
      <a-form :form="form" v-bind="formLayout">
        <a-row :gutter="[16,16]">
          <a-col :span="24">
            <a-form-item label="id" v-show="false">
              <a-input v-decorator="['id']" />
            </a-form-item>
            <a-form-item label="supplier_id" v-show="false">
              <a-input v-decorator="['supplier_id']" />
            </a-form-item>
            <a-form-item label="当前关联供应商名称">
              <a-tag closable @close="delet(item.id)" v-for="item in uclist" :key="item.id">
                {{ item.supplier.name }}
              </a-tag>
            </a-form-item>
            <a-form-item label="增加关联供应商">
              <a-auto-complete
                v-decorator="['corporation', {rules: [
                  {required: false, message: '必填项，请填写信息', trigger: 'change'}
                ]}]"
                :data-source="dataSource"
                placeholder="请输入"
                @select="onSelect"
                @search="onSearch"
                @change="onChange"
              />
              <a-button @click="adduc" type="info">增加</a-button>
              <div class="ant-form-explain" v-if="!!tip">  {{ tip }} </div>
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>
    </a-spin>
  </a-modal>
</template>

<script>
import pick from 'lodash.pick'
import AAutoComplete from 'ant-design-vue/es/auto-complete/'
import { supplier_list } from '@/api/supplier'
import { user_supplier_list, user_supplier_delete, user_supplier_create } from '@/api/user_supplier'

// 表单字段
const fields = ['id']
export default {
  props: {
    visible: {
      type: Boolean,
      required: true
    },
    loading: {
      type: Boolean,
      default: () => false
    },
    model: {
      type: Object,
      default: () => null
    }
  },
  components: {
    AAutoComplete
  },
  data () {
    this.formLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 7 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 13 }
      }
    }
    return {
      tip: '',
      form: this.$form.createForm(this),
      dictionaries: this.$Dictionaries,
      dataSource: [],
      corList: [],
      uclist: [],
      cursupplier_id: null
    }
  },
  created () {
    console.log('custom modal created', this.form)
    // 防止表单未注册
    fields.forEach(v => this.form.getFieldDecorator(v))
    this.form.setFieldsValue(pick(this.model, fields))
    // 当 model 发生改变时，为表单设置值
    this.$watch('model', () => {
      this.model && this.form.setFieldsValue(pick(this.model, fields))
    })
    this.getInfo()
  },
  methods: {
    delet (uc_id) {
      console.log(uc_id)
      user_supplier_delete(uc_id).then((res) => {
        console.log(res)
        console.log('删除成功------')
      })
    },
    getInfo () {
      user_supplier_list({ user_id: this.model.id }).then((res) => {
        console.log(res)
        this.uclist = res.data.entries
      })
    },
    onChange (value) {
      console.log(value)
    },
    onSearch (searchText) {
      supplier_list({ name: searchText }).then((res) => {
        const reslut = res.data.entries || []
        this.corList = reslut
        if (reslut.length === 0) {
          this.tip = '未找到您输入的供应商'
        } else {
          this.tip = ''
        }
        console.log('我是供应商列表', reslut)
        this.dataSource = !searchText ? [] : reslut.map((item) => item.name)
      })
    },
    onSelect (value) {
      console.log(value, '我是选择的值', this.corList)
      // eslint-disable-next-line eqeqeq
      const supplier_id = this.corList.filter((item) => (item.name === value) && item)[0].id
      this.form.setFieldsValue({
        supplier_id: supplier_id
      })
      this.cursupplier_id = supplier_id
      console.log('onSelect', value, this.corList.filter((item) => (item.name === value) && item))
    },
    adduc () {
      const param = Object.assign({ user_id: this.model.id, supplier_id: this.cursupplier_id })
      user_supplier_create(param).then(res => {
        console.log('删除成功------')
        this.form.setFieldsValue({
          corporation: ''
        })
        this.cursupplier_id = null
        this.getInfo()
      })
    }
  }
}
</script>
